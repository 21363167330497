<template>
  <div class="max-w-sm mx-auto">
    <div class="bg-white shadow-md px-4 pt-6 pb-24 relative">
      <div class="flex flex-col items-center">
        <img
          :src="current.logo_url ? current.logo_url : `${publicPath}img/logo-placeholder.png`"
          :alt="current.name"
          class="h-20 w-20 border"
        />
        <h2 class="text-3xl font-bold text-center mt-4">{{ current.name }}</h2>
      </div>

      <div class="absolute top-6 right-4 space-x-4">
        <button
          type="button"
          class="rounded-full focus:outline-none focus:ring"
          @click="showTenantDeleteConfirmation = true"
        >
          <i class="tmlps-i-delete-circle text-3xl h-8 w-8"></i>
        </button>

        <button
          type="button"
          class="rounded-full focus:outline-none focus:ring"
          @click="showTenantEditModal = true"
        >
          <i class="tmlps-i-edit-circle text-3xl h-8 w-8"></i>
        </button>
      </div>

      <div class="mt-5">
        <p class="text-base font-bold text-center">Información de la cuenta</p>

        <div class="py-4 border-b flex items-center justify-between">
          <p>Estatus de la empresa</p>

          <BaseSwitch
            :value="current.is_active"
            @input="handleSwitchInput"
            @on="handleSwitchOn"
            @off="handleSwitchOff"
          />
        </div>

        <div class="py-4 border-b flex items-center justify-between">
          <p>Dominio</p>
          <div>{{ current.primary_domain.name }}</div>
        </div>
      </div>

      <div class="mt-4">
        <p class="text-base font-bold text-center">Administrador de la cuenta</p>

        <div class="py-4 border-b flex items-center justify-between">
          <p>Nombre</p>
          <div>{{ current.owner_name }}</div>
        </div>

        <div class="py-4 flex items-center justify-between">
          <p>Correo electrónico</p>
          <div>{{ current.owner_email }}</div>
        </div>
      </div>
    </div>

    <TenantDisableConfirmationModal
      :show="showTenantDisableConfirmation"
      :tenant="current"
      @close="showTenantDisableConfirmation = false"
      @cancel="handleCancelTenantDisable"
    />

    <TenantEditModal
      :show="showTenantEditModal"
      :tenant="current"
      @close="showTenantEditModal = false"
    />

    <TenantDeleteConfirmationModal
      :show="showTenantDeleteConfirmation"
      :tenant="current"
      @close="showTenantDeleteConfirmation = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseSwitch from '@/components/BaseSwitch.vue';
import TenantDeleteConfirmationModal from '@/components/TenantDeleteConfirmationModal';
import TenantDisableConfirmationModal from '@/components/TenantDisableConfirmationModal';
import TenantEditModal from './Tenants/components/EditModal';

export default {
  components: {
    BaseSwitch,
    TenantDeleteConfirmationModal,
    TenantDisableConfirmationModal,
    TenantEditModal,
  },
  name: 'TenantProfile',
  data() {
    return {
      showTenantDisableConfirmation: false,
      showTenantEditModal: false,
      showTenantDeleteConfirmation: false,
    };
  },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
    ...mapState('tenant', ['current']),
  },
  methods: {
    async handleSwitchOn() {
      await this.$store.dispatch('tenant/enableTenant', this.tenant.current.id);
    },
    handleSwitchOff() {
      this.showTenantDisableConfirmation = true;
    },
    handleCancelTenantDisable() {
      this.$store.commit('tenant/ENABLE_TENANT');
    },
    handleSwitchInput(value) {
      if (value) {
        this.$store.commit('tenant/ENABLE_TENANT');
      } else {
        this.$store.commit('tenant/DISABLE_TENANT');
      }
    },
  },
};
</script>
