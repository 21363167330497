<template>
  <BaseModal :show="show" @close="handleCancelModal">
    <h3 slot="header" class="font-bold text-base">Editar empresa</h3>
    <div class="space-y-6">
      <!-- Company logo -->
      <div>
        <ImagePreviewInput
          v-model="companyLogo"
          :initial-preview-url="
            tenant.logo_url ? tenant.logo_url : `${publicPath}img/logo-placeholder.png`
          "
          class="flex flex-col items-center w-48 mx-auto"
        >
          <div slot="caption" class="mt-2 text-center">
            <p>Agrega un logo</p>
            <p class="text-xs mt-1">(La imágen de pesar menos de 300kb en formato .png o .jpg)</p>
          </div>
        </ImagePreviewInput>
        <div v-if="errors.has('company_logo')" class="text-sm text-red-600 pl-10 mt-1">
          {{ errors.first('company_logo') }}
        </div>
      </div>

      <!-- Company name -->
      <div>
        <BaseInput
          label="Nombre de la empresa"
          placeholder="Nombre de la empresa"
          :error="errors.first('name')"
          v-model="name"
        />
      </div>

      <!-- Company theme color -->
      <div>
        <div class="text-xs">Color principal</div>
        <BaseColorPicker v-model="color" class="mt-2" />
        <div v-if="errors.has('color')" class="text-sm text-red-600 pl-10 mt-1">
          {{ errors.first('color') }}
        </div>
      </div>

      <!-- Company subdomain -->
      <div>
        <BaseInput
          label="Subdominio"
          placeholder="Subdominio"
          :error="errors.first('domain')"
          v-model="domain"
        />
      </div>

      <h3 class="font-bold text-base text-center">Administrador de la cuenta</h3>
      <div>
        <BaseInput
          label="Nombre del contacto"
          placeholder="Nombre del contacto"
          :error="errors.first('owner_name')"
          v-model="ownerName"
        />
      </div>

      <div>
        <BaseInput
          label="Correo"
          placeholder="Correo"
          :error="errors.first('owner_email')"
          v-model="ownerEmail"
        />
      </div>
    </div>

    <div slot="footer" class="flex items-stretch justify-between space-x-3">
      <BaseButton class="flex-grow py-3" secondary @click="handleCancelModal">
        Cancelar
      </BaseButton>
      <BaseButton
        class="flex-grow py-3 inline-flex items-center justify-center"
        @click="handleUpdateTenant"
        :disabled="loading"
      >
        <svg
          v-if="loading"
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path></svg
        >Actualizar
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import Errors from '@/util/Errors';
import BaseButton from '@/components/BaseButton.vue';
import BaseColorPicker from '@/components/BaseColorPicker.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseModal from '@/components/BaseModal.vue';
import ImagePreviewInput from '@/components/ImagePreviewInput.vue';

export default {
  name: 'TenantEditModal',
  components: { BaseButton, BaseColorPicker, BaseInput, BaseModal, ImagePreviewInput },
  props: {
    show: { type: Boolean, default: false },
    tenant: { type: Object, required: true },
  },
  data() {
    return {
      companyLogo: null,
      name: this.tenant.name,
      color: this.tenant.theme_color,
      domain: this.tenant.primary_domain.name,
      ownerName: this.tenant.owner_name,
      ownerEmail: this.tenant.owner_email,
      loading: false,
      errors: new Errors(),
    };
  },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
  },
  methods: {
    handleCancelModal() {
      this.resetModal();
      this.$emit('close');
    },
    resetModal() {
      this.companyLogo = null;
      this.name = this.tenant.name;
      this.color = this.tenant.theme_color;
      this.domain = this.tenant.primary_domain.name;
      this.ownerName = this.tenant.owner_name;
      this.ownerEmail = this.tenant.owner_email;
      this.loading = false;
      this.errors.clear();
    },
    async handleUpdateTenant() {
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('color', this.color);
      formData.append('domain', this.domain);
      formData.append('owner_name', this.ownerName);
      formData.append('owner_email', this.ownerEmail);

      if (this.companyLogo) {
        formData.append('company_logo', this.companyLogo);
      }

      this.loading = true;
      this.errors.clear();

      try {
        const tenant = await this.$store.dispatch('tenant/updateTenant', {
          tenantId: this.tenant.id,
          data: formData,
        });
        this.$emit('close');
        this.$emit('updated', tenant);
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors.capture(error.response.data.errors);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
